<template>
    <div>
        <router-link
            v-if="linkType == 'router'"
            :to="{
                name: 'inventory-id',
                params: {
                    dealer_id: currentDealerId,
                    id: item.id
                }
            }">
            {{ item.id }}
        </router-link>
        <a
            v-else-if="linkType == 'link'"
            :href="item.vdp_url"
            target="_blank">
            View <icon
                class="mb-1 ml-1"
                name="external-link"
                size="10" />
        </a>
        <span v-else>
            -
        </span>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { mapState } from 'vuex';

export default {
    name: 'InventoryLink',
    components: {
        Icon
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        linkType: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
        })
    }
};
</script>