var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.linkType == 'router')?_c('router-link',{attrs:{"to":{
            name: 'inventory-id',
            params: {
                dealer_id: _vm.currentDealerId,
                id: _vm.item.id
            }
        }}},[_vm._v(" "+_vm._s(_vm.item.id)+" ")]):(_vm.linkType == 'link')?_c('a',{attrs:{"href":_vm.item.vdp_url,"target":"_blank"}},[_vm._v(" View "),_c('icon',{staticClass:"mb-1 ml-1",attrs:{"name":"external-link","size":"10"}})],1):_c('span',[_vm._v(" - ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }